<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6><a href="javascript:void(0)" class="text-info" @click="back()"> <i class="fas fa-arrow-left"></i></a> သတင်း ထည့်ရန်</h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputTitle" class="col-sm-2 col-form-label">သတင်း ခေါင်းစီး <span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="text" v-model="postRequest.title" @blur="validationRule()"
										class="form-control" id="inputTitle">
									<small class="text-danger">{{validationData.title}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputPostedDate" class="col-sm-2 col-form-label">ရက်စွဲ<span class="text-danger">*</span></label>
								<div class="col-sm-10">
									<input type="date" v-model="postRequest.posted_date" @blur="validationRule()"
										class="form-control" id="inputPostedDate">
									<small class="text-danger">{{validationData.posted_date}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputImage" class="col-sm-2 col-form-label">ဓာတ်ပုံ </label>
								<div class="col-sm-10">
									<input type="file" class="form-control" id="inputImage" @change="readImage">
									<img v-if="postRequest.image" v-bind:src="postRequest.image" class="banner-image mt-2"/>
								</div>
							</div>

							<div class="form-group row">
								<label class="col-sm-2 col-form-label">ဓာတ်ပုံ အဟောင်း </label>
								<div class="col-sm-10">
									<img v-if="currentImage" :src="env.mediaUrl+currentImage" class="banner-image mt-2"/>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">လုပ်ဆောင်မည်</button>
						</form>
					</div>
				</div>
			</div>
		</div>
		<b-modal ref="my-modal" hide-backdrop hide-footer>
			<template v-slot:modal-name>
				Alert
			</template>
			<div class="d-block text-center">
				<p>You must fill out the required fields before making other changes.</p>
			</div>
		</b-modal>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'postEdit',
		metaInfo: {
            title: "Post Edit",
            titleTemplate: "%s ← SC Dashboard",
        },
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				currentFile: "",
				currentImage: "",
				filter: {
					page: "",
                    reverse: "",
                    sort: "",
                    name: ""
				},
				postRequest: {
					id: "",
					title: "",
					posted_date: "",
					image: ""
				},
				validationData: {
					title: "",
					posted_date: ""
				}
			}
		},
		watch: {
			async $route(to, from) {
			},
		},
		computed: {},
		methods: {
			...mapActions({
				postUpdateAction: 'postUpdateAction',
				postFieldsAction: 'postFieldsAction'
			}),
			readImage({ target }) {
				const files = target.files
				const fileReader = new FileReader()
				fileReader.readAsDataURL(files[0])
				fileReader.onload = e => {
					this.postRequest.image = e.target.result
				};
			},
			async getFields(){
				let option = {
					fields: {id: this.postRequest.id}
				}
				await this.postFieldsAction({
					...option
				}).then(res => {
					this.postRequest.title = res.data.title
					this.postRequest.posted_date = res.data.posted_date
					this.currentImage = res.data.image
					this.isLoading = false
				}).catch(err => this.isLoading = true)
			},
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = this.postRequest
				await this.postUpdateAction({
					...option
				}).then(res => {
					this.back()
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			back() {
				this.$router.replace({ path: 'post-list', query: { page: this.filter.page, reverse: this.filter.reverse, sort: this.filter.sort, title: this.filter.title} }).catch(()=>{})
			},
			validationRule() {
				let isValidation = []
				if (this.postRequest.title == "") {
					this.validationData.title = "The title field is required."
					isValidation.push(true)
				} else {
					this.validationData.title = ""
					isValidation.push(false)
				}
				if (this.postRequest.posted_date == "") {
					this.validationData.posted_date = "The posted_date field is required."
					isValidation.push(true)
				} else {
					this.validationData.posted_date = ""
					isValidation.push(false)
				}

				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
			this.filter.page = this.$route.query.page
            this.filter.reverse = this.$route.query.reverse
            this.filter.sort = this.$route.query.sort
			this.postRequest.id = this.$route.query.id
			this.filter.title = this.$route.query.title
			this.getFields()
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>